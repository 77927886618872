
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import { PageComponent } from '@/store/page';
    import Modal from '@/components/blocks/Modal.vue';
    import BaseColors from '@/components/blocks/BaseColors.vue';
    import { AvailableTypes } from '@/components/editor/config/types';

    export const meta = {
        inner: true,
        title: 'Модальное окно базовых цветов',
        options: {
            type: AvailableTypes.componentOptions,
            componentName: 'BaseColors'
        }
    };

    @Component({
        components: { BaseColors, Modal }
    })
    export default class BaseColorsModal extends Vue {
        @Prop() component: PageComponent;
        @Prop() options;
        @Prop() name;
    }
