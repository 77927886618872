import { render, staticRenderFns } from "./BaseColorsModal.vue?vue&type=template&id=7c480858&"
import script from "./BaseColorsModal.vue?vue&type=script&lang=ts&"
export * from "./BaseColorsModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports