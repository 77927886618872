var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"button-block",class:{ 'button-block--white': !_vm.isColorFull, 'mt-0': _vm.isSmall }},[(_vm.link)?[_c('a',{class:[
                'button-block__button button',
                _vm.text !== undefined && _vm.text.length > 20 ? 'button--big' : '',
                _vm.isSmall ? 'small-text' : '',
                _vm.buttonClass
            ],style:(_vm.addStyle('border-color', _vm.borderColor, _vm.getStyle(_vm.backgroundText))),attrs:{"href":_vm.link,"target":_vm.targetBlank},on:{"click":_vm.triggerClick}},[_vm._v(_vm._s(_vm.text))])]:[_c('button',{class:[
                'button-block__button button',
                _vm.text !== undefined && _vm.text.length > 20 ? 'button__big' : '',
                _vm.isSmall ? 'small-text' : '',
                _vm.buttonClass
            ],style:(_vm.addStyle('border-color', _vm.borderColor, _vm.getStyle(_vm.backgroundText))),attrs:{"disabled":_vm.disabled},on:{"click":_vm.triggerClick}},[_vm._v("\n            "+_vm._s(_vm.text)+"\n        ")])],_vm._v(" "),_vm._l((2),function(i){return _c('div',{key:i,staticClass:"button-block__border",style:(_vm.getBorderColor())})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }