
    import { Component, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import { optionsDefault } from '@/components/editor/config/optionsDefault';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import { Image } from '@/components/blocks/TabsWithImagesAndText.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import InlineSlider from '@/components/blocks/InlineSlider.vue';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';

    export interface AdditionalOption {
        title: string;
        images: Array<Image>;
        text: string;
        link: string;
        targetBlank: boolean;
    }

    export const meta = {
        title: 'Дополнительные опции',
        options: {
            head: {
                type: AvailableTypes.string,
                title: 'Заголовок',
                default: 'Возможны дополнительные опции'
            },
            text: {
                type: AvailableTypes.string,
                title: 'Текст',
                default: ''
            },
            options: {
                title: 'Опции',
                type: AvailableTypes.array,
                item: {
                    title: {
                        type: AvailableTypes.string,
                        title: 'Заголовок'
                    },
                    text: {
                        type: AvailableTypes.string,
                        title: 'Текст'
                    },
                    images: {
                        type: AvailableTypes.arrayOfType,
                        title: 'Картинки',
                        item: {
                            type: AvailableTypes.image,
                            title: 'Картинка'
                        }
                    },
                    link: {
                        type: AvailableTypes.string,
                        title: 'Ссылка'
                    },
                    targetBlank: {
                        title: 'Открывать ссылку в новом окне',
                        type: AvailableTypes.boolean,
                        default: false
                    }
                },
                default: optionsDefault.AdditionalOptions
            }
        },
        group: AvailableGroups.Список
    };

    @Component({
        components: { OptionsFieldsInlineEditor, InlineSlider, ImageComponent }
    })
    export default class AdditionalOptions extends PageBlockMixin {
        @Prop(Array) options: AdditionalOption[];
        @Prop() component;

        currentExpanded: number | null = 0;

        get currentImg(): Array<Image> {
            if (this.options && this.currentExpanded !== null) {
                return this.options[this.currentExpanded].images;
            }
            return [];
        }

        setCurrentExpanded(index) {
            this.currentExpanded = index;
        }

        isCurrent(index) {
            return index != this.currentExpanded;
        }
    }
