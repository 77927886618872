
    import { Component, namespace, Vue } from 'nuxt-property-decorator';
    import BreadCrumbItem from '@/components/common/BreadCrumbItem.vue';

    const page = namespace('page');
    const pages = namespace('pages');

    @Component({
        components: { BreadCrumbItem }
    })
    export default class BreadCrumbs extends Vue {
        @page.State options;
        @pages.State groups;

        categoryTitle = '';
        subcategoryTitle = '';

        get isAdminPage() {
            return this.$route.path.indexOf('admin') !== -1;
        }

        async created() {
            if (!this.groups) {
                return;
            }

            this.groups.forEach(group => {
                if (this.$route.params.category === group.options.slug) {
                    this.categoryTitle = group.options.name;
                }

                if (this.$route.params.subcategory === group.options.slug) {
                    this.subcategoryTitle = group.options.name;
                }
            });
        }
    }
