
    import { Component, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import { mixins } from 'nuxt-property-decorator';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import SliderWrapper from '@/components/blocks/SliderWrapper.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import { optionsDefault } from '@/components/editor/config/optionsDefault';
    import swipe from '@/directives/swipe';

    export interface Card {
        img: string;
        title?: string;
        text: string;
    }

    export const meta = {
        title: 'Карточки в колонках',
        options: {
            head: {
                type: AvailableTypes.string,
                title: 'Заголовок',
                default: 'Этапы работы над заказом'
            },
            cards: {
                title: 'Элементы',
                type: AvailableTypes.array,
                item: {
                    title: {
                        type: AvailableTypes.editor,
                        title: 'Заголовок'
                    },
                    text: {
                        type: AvailableTypes.editor,
                        title: 'Текст'
                    },
                    img: {
                        type: AvailableTypes.image,
                        title: 'Картинка'
                    }
                },
                default: optionsDefault.CardList
            }
        },
        group: AvailableGroups.Плитка
    };

    @Component({
        components: { OptionsFieldsInlineEditor, ImageComponent, SliderWrapper },
        directives: { swipe }
    })
    export default class CardList extends mixins(PageBlockMixin) {
        @Prop(Array) cards: Card[];
        @Prop() component;

        get cardWidth() {
            return this.cards.length >= 4 ? 3 : 4;
        }
    }
