
    import { Component, Prop, mixins } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';

    export interface CategoryBlog {
        name: string;
        link: string;
    }
    export interface TypeOfBlog {
        img: string;
        title: string;
        text: string;
        category: CategoryBlog;
        link: string;
    }
    @Component({
        components: { ImageComponent }
    })
    export default class Blog extends mixins(PageBlockMixin) {
        @Prop(Array) items: TypeOfBlog[];
    }
