
    import { Component, Emit, Prop } from 'nuxt-property-decorator';
    import PaletteMixin from '@/mixins/paletteMixin';

    @Component
    export default class Button extends PaletteMixin {
        @Prop(String) text;
        @Prop(Boolean) isColorFull;
        @Prop(Boolean) disabled;
        @Prop() borderColor;
        @Prop() link;
        @Prop() targetBlank;
        @Prop() isSmall?;
        @Prop({ default: () => [] }) buttonClass: string[];

        getStyle(style) {
            if (this.isColorFull) {
                return style;
            } else {
                return '';
            }
        }

        @Emit('click')
        triggerClick($event) {
            return $event;
        }

        getBorderColor() {
            return this.borderColor
                ? { 'border-color': this.borderColor }
                : this.getStyle(this.borderText);
        }

        addStyle(prop, value, style) {
            return { ...style, [prop]: value };
        }
    }
