
    import { Vue, Component, Prop } from 'nuxt-property-decorator';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import { optionsDefault } from '@/components/editor/config/optionsDefault';
    import SliderWrapper from '@/components/blocks/SliderWrapper.vue';
    import swipe from '@/directives/swipe';
    import striptags from 'striptags';
    import SliderBullets from '@/components/common/SliderBullets.vue';

    export const meta = {
        title: 'Карточки в две колонки',
        options: {
            head: {
                type: AvailableTypes.editor,
                title: 'Заголовок',
                default: 'Что нужно делать на месте'
            },
            cards: {
                title: 'Элементы',
                type: AvailableTypes.array,
                item: {
                    title: {
                        type: AvailableTypes.editor,
                        title: 'Заголовок'
                    },
                    link: {
                        type: AvailableTypes.link,
                        default: {}
                    },
                    text: {
                        type: AvailableTypes.editor,
                        title: 'Текст'
                    },
                    img: {
                        type: AvailableTypes.image,
                        title: 'Изображение'
                    }
                },
                default: optionsDefault.CardsInTwoColumns
            }
        },
        group: AvailableGroups.Плитка
    };

    @Component({
        components: {
            SliderBullets,
            SliderWrapper,
            OptionsFieldsInlineEditor,
            ImageComponent
        },
        directives: { swipe }
    })
    export default class CardList extends Vue {
        @Prop() head;
        @Prop() cards;
        @Prop() component;

        striptags = striptags;
    }
