
    import { Component, Prop } from 'nuxt-property-decorator';
    import { mixins } from 'nuxt-property-decorator';
    import PaletteMixin from '@/mixins/paletteMixin';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import WindowMixin from '@/mixins/windowMixin';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';

    export interface Tab {
        img: string;
        title: string;
        text: string;
    }

    const defaultValue = [
        {
            img: {
                src: ''
            },
            title: '',
            text: ''
        }
    ];

    export const meta = {
        title: 'Карточки с текстом и иконками',
        options: {
            head: {
                type: AvailableTypes.string,
                title: 'Заголовок'
            },
            types: {
                title: 'Виды',
                type: AvailableTypes.array,
                item: {
                    img: { type: AvailableTypes.image, title: 'Картинка' },
                    title: {
                        type: AvailableTypes.string,
                        title: 'Заголовок'
                    },
                    text: {
                        type: AvailableTypes.editor,
                        title: 'Текст'
                    }
                },
                default: defaultValue
            }
        },
        group: AvailableGroups.Плитка
    };

    @Component({
        components: { ImageComponent }
    })
    export default class CardsListWithIcon extends mixins(
        PaletteMixin,
        PageBlockMixin,
        WindowMixin
    ) {
        @Prop(Array) types: Tab[];
    }
