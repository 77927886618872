
    import { Component, mixins, Prop } from 'nuxt-property-decorator';
    import { namespace } from 'vuex-class';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import Button from '@/components/blocks/Button.vue';
    import PaletteMixin from '@/mixins/paletteMixin';
    import { PageComponent } from '@/store/page';
    import FormColorNotFound from '@/components/blocks/FormColorNotFound.vue';
    import { IField } from '@/mixins/formMixin';
    import Color from '@/components/blocks/colors/Color.vue';
    import Modal from '@/components/blocks/Modal.vue';
    import BaseTabs from '@/components/blocks/base/BaseTabs.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import ColorsTabs from '@/components/blocks/base/ColorsTabs.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';

    export interface IColor {
        color: string;
        name?: string;
        active?: boolean;
    }

    const settings = namespace('settings');

    export const meta = {
        title: 'Базовые цвета',
        options: {
            head: {
                type: AvailableTypes.string,
                title: 'Заголовок',
                default: 'Более 40 цветов флиса от производителей'
            },
            text: {
                type: AvailableTypes.string,
                title: 'Текст',
                default:
                    'Флис это круто — мы не работаем с другими материалами потому что. Более 40 цветов флиса от разных производителей. Посмотрите сами, если не верите'
            },
            tabs: {
                type: AvailableTypes.select,
                title: 'Цвета',
                values: {
                    type: AvailableTypes.apiSource,
                    path: '/entities/colors/',
                    method: 'get',
                    conditions: {},
                    mappings: {
                        value: '_id',
                        text: ['options.name', 'options.description']
                    }
                },
                multiple: true
            },
            form: {
                type: AvailableTypes.component,
                componentName: 'FormColorNotFound',
                title: 'Блок не нашли цвет'
            },
            modal: {
                type: AvailableTypes.boolean,
                title: 'Внутри модалки'
            }
        },
        group: AvailableGroups.Цвета
    };

    @Component({
        components: {
            ColorsTabs,
            OptionsFieldsInlineEditor,
            BaseTabs,
            Modal,
            Color,
            FormColorNotFound,
            Button
        }
    })
    export default class BaseColors extends mixins(PaletteMixin, PageBlockMixin) {
        @Prop() head;
        @Prop() text: string;
        @Prop() name: string;
        @Prop() tabs: any;
        @Prop() component: PageComponent;
        @Prop() index;
        @Prop() form;
        @Prop({ default: false }) modal;
        @settings.Action get;
        formFields: IField[] = [];
        activeColor: null | number = null;
    }
